import React from "react";
import styled from "styled-components";
// import HeroTitle from "../components/HeroTitle";
// import BackgroundImg from "../assets/hero_bg.png";
import BackgroundImg from "../assets/FTT-hero.png";
import PosterImg from "../assets/poster-overlay.png";

// const Hero = () => <Wrapper bg={BackgroundImg}>{/* <HeroTitle /> */}</Wrapper>;
const Hero = () => (
  <Wrapper>
    <Img src={BackgroundImg} />
  </Wrapper>
);

export default Hero;

const Img = styled.img`
  width: 100vw;
  height: auto;
  max-width: 1200px;
  padding: 20px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const Wrapper = styled.div<any>`
  padding-top: 50px;
  justify-content: center;
  display: flex;
  background-color: #f6f5e7;

  @media (max-width: 768px) {
    background: url(${PosterImg}) black;
    height: 350px;
    background-position: top center;
    background-size: cover;
  }
`;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import gTFLogo from "../assets/Primary-Arched_solid copy.png";
import DonateBtn from "./Btn";

const Links = () => (
  <>
    <NavLinkWrapper>
      <NavLink href="https://greentop.farm/collections/wfh-packs">
        WFH Packs
      </NavLink>
    </NavLinkWrapper>
    <NavLinkWrapper>
      <NavLink href="https://greentop.farm/collections/catering">
        Catering
      </NavLink>
    </NavLinkWrapper>
    <NavLinkWrapper>
      <NavLink href="https://greentop.farm/pages/our-approach">
        Our Approach
      </NavLink>
    </NavLinkWrapper>
    <NavLinkWrapper>
      <NavLink href="https://greentop.farm/pages/our-partner-farms">
        Farms
      </NavLink>
    </NavLinkWrapper>
    <DonateBtn href="#donate">Donate</DonateBtn>
  </>
);
const Nav = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);

  useEffect(() => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    if (width < 760) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [setIsMobile]);

  return (
    <NavWrapper>
      <ImgWrapper>
        <Logo href="http://greentop.farm">
          <GTFLogo src={gTFLogo} />
        </Logo>
      </ImgWrapper>
      {!isMobile ? (
        <Links />
      ) : (
        <>
          <Menu onClick={() => setToggleMenu(!toggleMenu)}>Menu</Menu>
          {toggleMenu && (
            <MobileWrapper>
              <Links />
            </MobileWrapper>
          )}
        </>
      )}
    </NavWrapper>
  );
};

export default Nav;

const Logo = styled.a`
  display: flex;
  align-items: center;
`;

const Menu = styled.div`
  font-family: Oswald;
  display: flex;
  font-size: 22px;
  font-weight: 500;
  align-items: center;
`;

const NavWrapper = styled.div`
  width: 100vw;
  height: 80px;
  background: #fff;
  position: fixed;
  display: grid;
  grid-template-columns: 150px 100px 100px 100px 100px 1fr;
  z-index: 99999;

  ${DonateBtn} {
    justify-self: flex-end;
    align-self: center;
    margin-right: 50px;
  }

  @media (max-width: 768px) {
    display: inline-flex;
    justify-content: space-around;
  }
`;

const NavLink = styled.a`
  text-decoration: none;
  font-family: Oswald;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #000;
  text-transform: uppercase;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  transition: all ease 0.2s;
  padding-bottom: 6px;
`;

const NavLinkWrapper = styled.a`
  text-decoration: none;
  font-family: Oswald;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  display: flex;

  &:hover {
    ${NavLink} {
      border-bottom: 2px solid #ed6a1e;
      display: inline-block;
    }
  }
`;

const MobileWrapper = styled.div`
  background: white;
  padding: 20px;
  position: fixed;
  width: 100%;
  top: 80px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #0000005c;

  ${DonateBtn} {
    margin-right: 0px;
  }

  ${NavLink} {
    padding: 15px 0px;
  }
`;
const GTFLogo = styled.img`
  height: 50px;
  width: auto;
`;

const ImgWrapper = styled.div`
  display: flex;
  padding-left: 50px;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    padding-left: 0px;
  }
`;

import React from "react";
import "./App.css";
import Description from "./sections/Description";
import DonationSection from "./sections/DonationSection";
import Footer from "./sections/Footer";
import Hero from "./sections/Hero";
import SubHero from "./sections/SubHero";
import Nav from "./components/Nav";
import SubHeroImg from "./sections/SubHeroImg";

function App() {
  return (
    <div className="App">
      <Nav />
      <Hero />
      <SubHero />
      <SubHeroImg />
      <Description />
      <DonationSection />
      <Footer />
    </div>
  );
}

export default App;

/* eslint-disable */
/* @ts-ignore */

import React from "react";
import styled from 'styled-components';

const Donation = () => (
  <>
    {/* @ts-ignore */}
    <script src="https://donorbox.org/widget.js" paypalExpress={false}></script>
    <iframe
      frameborder="0"
      height="900px"
      name="donorbox"
      scrolling="no"
      seamless={true}
      src="https://donorbox.org/embed/farm-to-tables-in-need"
      width="100%"
    ></iframe>
  </>
);

export default Donation;

const Wrapper = styled.div``;
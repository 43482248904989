import React from "react";
import styled from "styled-components";
import Donation from "./Donation";
import donFood from "../assets/don_food.png";
import donCar from "../assets/don_car-full.png";
import farmHero from "../assets/farm-hero.png";
import SectionTitle from "../components/SectionTitle";

const DonationSection = () => (
  <Wrapper>
    <span id="donate"> &nbsp; </span>
    <SectionTitle
      topText={"Donate today"}
      bottomText={"Help feed people in need."}
    />
    <WrapperGrid>
      <Left>
        <Donation />
      </Left>

      <Right>
        <Col1>
          <Img src={donFood} />
          {/* <Text>Donate to help feed people in need.</Text> */}
        </Col1>
        <Col2>
          <Img src={farmHero} />
          <Img src={donCar} />
        </Col2>
      </Right>
    </WrapperGrid>
  </Wrapper>
);

export default DonationSection;

const Wrapper = styled.div`
  padding: 60px 5%;
  background: #f7ede0;

  @media (max-width: 768px) {
    padding: 40px 15px;
  }
`;

const WrapperGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const Img = styled.img`
  width: 100%;
  padding: 20px 0px 0px 0px;
`;
const Left = styled.div`
  //   padding: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  min-width: 426px;

  @media (max-width: 768px) {
    padding: 0px;
    min-width: 0px;
    width: 100%;
  }
`;

const Text = styled.div`
  font-size: 28px;
  font-weight: 900;
  padding: 50px 20px;
`;

const Right = styled.div`
  display: grid;
  grid-template-columns: 300px 300px;
  grid-column-gap: 20px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Col1 = styled.div``;
const Col2 = styled.div``;

import React from "react";
import styled from "styled-components";

const DonateBtn = styled.a`
  background: #131d60;
  padding: 10px 24px;
  width: 140px;
  border-radius: 4px;
  color: #f7ede0;
  text-decoration: none;
  font-weight: 500;
  display: inline-flex;
  transition: all ease 0.2s;
  height: 26px;
  align-items: center;
  justify-content: center;
  font-family: Oswald;

  &:hover {
    background: #131d60;
  }
`;

export default DonateBtn;

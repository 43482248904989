import React from "react";
import styled from "styled-components";
import BackgroundImg from "../assets/gtf-crew-subhero.png";
import PosterImg from "../assets/poster-overlay.png";
import HeroTitle from "../components/HeroTitle";

const SubHeroImg = () => (
  <Wrapper>
    <Img src={BackgroundImg} />
    <StyledHeroTitle>
      <HeroTitle />
    </StyledHeroTitle>
  </Wrapper>
);

export default SubHeroImg;

const StyledHeroTitle = styled.div`
  position: absolute;
  bottom: -70px;
  right: 5%;
`;

const Img = styled.img`
  width: 100vw;
  height: auto;
`;

const Wrapper = styled.div<any>`
  position: relative;
  margin-bottom: 100px;

  @media (max-width: 768px) {
    height: 400px;
    margin-bottom: 40px;

    ${StyledHeroTitle} {
      position: initial;
      margin-top: -5px;
    }
  }
`;

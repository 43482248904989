import React from "react";
import styled from "styled-components";
import window1 from "../assets/window1.png";
import window2 from "../assets/window2.png";
import window3 from "../assets/window3.png";
import window4 from "../assets/window4.png";

const Window = () => {
  return (
    <WindowWrapper>
      <Img src={window1} />
      <Img src={window2} />
      <Img src={window3} />
      <Img src={window4} />
    </WindowWrapper>
  );
};

export default Window;

const WindowWrapper = styled.div`
  display: grid;
  grid-template-columns: 250px 250px;
  grid-template-rows: 250px 250px;
  grid-gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 150px 150px;
    grid-template-rows: 150px 150px;
  }
`;

const Img = styled.img`
  width: 250px;
  height: 250px;

  @media (max-width: 768px) {
    width: 150px;
    height: 150px;
  }
`;

import React from "react";
import styled from "styled-components";
import gTFLogo from "../assets/Primary-Arched_solid copy.png";
const Footer = () => (
  <Wrapper>
    <Text>
      Thank you for your support! Green Top Farms has partnered with Lucha Vida,
      a US 501(c)3 based in NY, to take your donation. Your contribution is tax
      deductible. We work with local partners to get highly nutritious meals to
      children and families in need and we appreciate your support!
    </Text>
    <ImgWrapper>
      <a href="http://greentop.farm">
        <GTFLogo src={gTFLogo} />
      </a>
    </ImgWrapper>
  </Wrapper>
);

export default Footer;

const Wrapper = styled.div`
  color: #f7ede0;
  background: #131c5f;
  padding: 50px 10%;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`;
const GTFLogo = styled.img`
  width: 125px;
`;
const ImgWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    padding-top: 50px;
  }
`;
const Text = styled.div`
  font-size: 18px;
  font-family: Oswald;
`;

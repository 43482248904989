import React from "react";
import styled from "styled-components";

const SectionTitle = (props: any) => (
  <Wrapper>
    <Row>
      <GTF>{props.topText}</GTF>
      <Dash />
    </Row>
    <Row>
      <Dash />
      <SSS>{props.bottomText}</SSS>
    </Row>
  </Wrapper>
);

export default SectionTitle;

const Wrapper = styled.div`
  margin-bottom: 40px;
  padding-left: 20px;

  @media (max-width: 768px) {
    padding: 0px;
  }
`;

const Dash = styled.span`
  background: #ed6a1e;
  height: 4px;
  border-radius: 10px;
  width: 100px;
  display: inline-flex;

  @media (max-width: 768px) {
    width: 50px;
  }
`;
const Row = styled.div`
  font-weight: 900;
  font-size: 34px;
  display: flex;
  align-items: center;
  color: #131d60;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;
const GTF = styled.div`
  padding: 0 17px;
`;
const SSS = styled.div`
  padding: 0 17px;
`;

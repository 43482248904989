import React from "react";
import styled from "styled-components";
import DonateBtn from "./Btn";

const HeroTitle = () => (
  <Wrapper>
    <HeroText>
      Green Top Farms and Something Special Studios have joined together in
      support of the NYC community.
    </HeroText>
    <DonateBtn href="#donate">Donate today</DonateBtn>
  </Wrapper>
);

export default styled(HeroTitle)``;

const Wrapper = styled.div`
  background: #f7ede0;
  color: #131c5f;
  padding: 40px 50px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 850px;
  box-shadow: 0px 0px 4px #00000030;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const HeroText = styled.span`
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.01em;
  max-width: 550px;
  text-align: left;
  margin-right: 30px;
  font-family: Oswald;

  @media (max-width: 768px) {
    margin: 0px;
    margin-bottom: 30px;
    max-width: auto;
  }
`;

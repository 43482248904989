import React from "react";
import styled from "styled-components";

const SubHero = () => (
  <Wrapper>
    <TextWrapper>
      <Text>Feed people in need.</Text>
      <Text>Support local business.</Text>
      <Text>Support local farms.</Text>
    </TextWrapper>
  </Wrapper>
);

export default SubHero;

const Wrapper = styled.div`
  background: #ed6a1e;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    height: 130px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    padding-left: 25px;
  }
`;
const Text = styled.div`
  color: #fff;
  font-weight: 500;
  font-size: 24px;
  padding: 0px 20px;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
    padding: 0px 10px;
  }
`;

import React from "react";
import styled from "styled-components";
import SectionTitle from "../components/SectionTitle";
import windowImg from "../assets/window_image.png";
import gTFLogo from "../assets/Primary-Arched_solid copy.png";
import Window from "../components/Window";

const Description = () => (
  <Wrapper>
    <SectionTitle
      bottomText={"Something Special Studios"}
      topText={"Green Top Farms"}
    />
    <Content>
      <ImgWrapper>
        {/* <WindowImg src={windowImg} /> */}
        <Window />
      </ImgWrapper>

      <TextWrapper>
        <Text>
          <div>{`     We're raising money to prepare fresh, nutritious meals for those in
          need during these unprecedented times. Meals prepared by Green Top
          Farms will be donated to residents in underserved NYC neighborhoods
          via a selection of non-profit partners, starting with New York Common
          Pantry in Harlem.`}</div>
          <br />
          <div>{`     Our commitment is to use every single dollar to make
          as many meals as we possibly can for people in need.`}</div>
        </Text>
        <ImgWrapper>
          <GTFLogo src={gTFLogo} />
        </ImgWrapper>
      </TextWrapper>
    </Content>
  </Wrapper>
);

export default Description;

const Wrapper = styled.div`
  padding: 60px 5%;

  @media (max-width: 768px) {
    padding: 60px 15px 20px;
  }
`;
const Text = styled.div`
  div {
    text-indent: 35px;
  }
  text-transform: uppercase;
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 0 60px;
  @media (max-width: 968px) {
    padding: 0 20px;
  }

  @media (max-width: 768px) {
    margin: 40px 0;
  }
`;
const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;
const WindowImg = styled.img`
  //   max-width: 550px;
  //   height: auto;

  @media (max-width: 768px) {
    // max-width: 80%;
    // max-height: 450px;

    height: calc(100vw - 40px);
    width: calc(100vw - 40px);
  }
`;
const GTFLogo = styled.img`
  @media (max-width: 768px) {
    margin-top: 60px;
  }
`;
const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
